@use 'material/theme' as jr-theme;

$accessible-against-white: #838589;

$dark-text: rgba(0, 0, 0, 0.9);
$medium-text: rgba(0, 0, 0, 0.6);
$light-text: rgba(255, 255, 255, 1);
$disabled-text: $accessible-against-white;
$black-text: rgba(0, 0, 0);
$gray-text: #b3b3b3;

$dark-disabled-text: $disabled-text;
$dark-dividers: rgba($dark-text, 0.12);
$dark-border: #8c8c8c;
$dark-focused: rgba($dark-text, 0.12);

$light-disabled-text: $disabled-text;
$light-dividers: rgba($light-text, 0.12);
$light-focused: rgba($light-text, 0.12);

$disabled-input-text: #616161;
$disabled-input-background: #f7f7f7;

$alt-row-background: #ebf1f8;

$gradient-triple: linear-gradient(
  45deg,
  #3a70bc -18.72%,
  #4fa0c8 63.95%,
  #5ab3b5 100%
);

$gradient-triple-accent: linear-gradient(
  63.14deg,
  #5a78f9 12.62%,
  #e37b91 77.42%
);

$gradient-double-cool: linear-gradient(45deg, #3a70bc 0.41%, #4fa0c8 100%);

$gradient-double-hot: linear-gradient(45deg, #4fa0c8 0.41%, #5ab3b5 100%);

// alias all maps and colors here so devs don't need to know too many Angular Material SCSS internals in order to work with these styles.
$primary-palette: map-get(jr-theme.$jr-theme, primary);
$accent-palette: map-get(jr-theme.$jr-theme, accent);
$warn-palette: map-get(jr-theme.$jr-theme, warn);

$color-primary: jr-theme.get-color-from-palette($primary-palette);
$color-primary--lighter: jr-theme.get-color-from-palette(
  $primary-palette,
  lighter
);
$color-primary--darker: jr-theme.get-color-from-palette(
  $primary-palette,
  darker
);

$color-accent: jr-theme.get-color-from-palette($accent-palette);
$color-accent--lighter: jr-theme.get-color-from-palette(
  $accent-palette,
  lighter
);
$color-accent--darker: jr-theme.get-color-from-palette($accent-palette, darker);

$color-warn: jr-theme.get-color-from-palette($warn-palette);
$color-warn--lighter: jr-theme.get-color-from-palette($warn-palette, lighter);
$color-warn--darker: jr-theme.get-color-from-palette($warn-palette, darker);

// get background and background colors
$backgrounds-map: map-get(jr-theme.$jr-theme, background);
$foregrounds-map: map-get(jr-theme.$jr-theme, foreground);

$color-background: map-get($backgrounds-map, background);
$color-background-light: $color-background;
$color-background-dark: #f2f3f3;
$color-disabled-background: map-get($backgrounds-map, disabled-button);
$color-disabled-foreground: map-get($foregrounds-map, disabled-text);

$color-dark-shadow: rgba(0, 0, 0, 0.15);

// spacing
$spacing: 8px;
$spacing-0: $spacing * 0; // 0px
$spacing-1: $spacing * 1; // 8px
$spacing-2: $spacing * 2; // 16px
$spacing-3: $spacing * 3; // 24px
$spacing-4: $spacing * 4; // 32px
$spacing-5: $spacing * 5; // 40px
$spacing-6: $spacing * 6; // 48px
$spacing-7: $spacing * 7; // 56px
$spacing-8: $spacing * 8; // 64px
$spacing-9: $spacing * 9; // 72px
$spacing-10: $spacing * 10; // 80px
$spacing-11: $spacing * 11; // 88px
$spacing-12: $spacing * 12; // 96px
$spacing-13: $spacing * 13; // 104px
$spacing-14: $spacing * 14; // 112px
$spacing-15: $spacing * 15; // 120px
$spacing-16: $spacing * 16; // 128px
$spacing-17: $spacing * 17; // 136px
$spacing-18: $spacing * 18; // 144px
$spacing-19: $spacing * 19; // 152px
$spacing-20: $spacing * 20; // 160px

$spacing-map: (
  0: $spacing-0,
  1: $spacing-1,
  2: $spacing-2,
  3: $spacing-3,
  4: $spacing-4,
  5: $spacing-5,
  6: $spacing-6,
  7: $spacing-7,
  8: $spacing-8,
  9: $spacing-9,
  10: $spacing-10,
  11: $spacing-11,
  12: $spacing-12,
  13: $spacing-13,
  14: $spacing-14,
  15: $spacing-15,
  16: $spacing-16,
  17: $spacing-17,
  18: $spacing-18,
  19: $spacing-19,
  20: $spacing-20,
);

$layout-side-padding: $spacing-2;

// global element dimensions
$layout-max-width: 1200px;
$header-height: $spacing-6;
$mobile-nav-height: $spacing-9;
$body-max-width: 672px;
$longformfield-max-width: 360px;
$shortformfield-max-width: $spacing-20;

// standard rounded corner dimensions
$rounded-corner: $spacing-1;

// zindices
$overlay-zindex: 9999999;
$menus-zindex: 9999;
$sidebar-zindex: 100;
$drag-drop-zindex: $overlay-zindex + 1;

// colors
// TODO: These are duplicates of colors we already have defined
$salmon: #ea6a7a;
$cornflowerBlue: #2f3a8a;
$carolinaBlue: #4fa0c8;
$oldSilver: #838589;
$apple: #67b55a;
$white: #fff;
$black: #000;
$almostBlack: #1b1b1c;
$charcoal: #1f232b;
$tangerine: #f0a048;
$grayMeImpressed1: #bfbfbf;
$grayMeImpressed2: #808080;
$grayMeImpressed3: #848484;
$grayMeImpressed4: #494949;
$jimmypurple: #5b35f4;
$jimmypurplelight: #9990ff;
$moneygreen: #0bca07;
$errorRed: #cc4814;
$offWhite: #f5f5f5;
$darkSpring: #198754;
$darkSpringLight: #41cc89;

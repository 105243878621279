@use '@angular/material' as mat;
@forward '@angular/material';

/**
 * Jobring color palettes
 * Note: Angular Material ships with base palettes but we need colors that aren't available in them.
 *       These custom palettes provide base colors plus lighter/darker variants for each.
*/

$jr-black: (
  100: #d1d0d0,
  500: #1d2c37,
  900: #000000,
  contrast: (
    100: rgba(black, 0.87),
    500: white,
    900: white,
  ),
);

$jr-blue: (
  100: #9db8de,
  500: #3a70bc,
  900: #1d385e,
  contrast: (
    100: rgba(black, 0.87),
    500: white,
    900: white,
  ),
);

$jr-cyan: (
  100: #a7d0e4,
  500: #5a78f9,
  900: #285064,
  contrast: (
    100: rgba(black, 0.87),
    500: white,
    900: white,
  ),
);

$jr-red: (
  100: #ce667b,
  500: #ad0023,
  900: #680015,
  contrast: (
    100: rgba(black, 0.87),
    500: white,
    900: white,
  ),
);

$jr-turquoise: (
  100: #add9da,
  500: #5ab3b5,
  900: #2d5a5b,
  contrast: (
    100: rgba(black, 0.87),
    500: white,
    900: white,
  ),
);

// Define theme
$jr-primary: mat.define-palette($jr-black, 500, 100, 900);
$jr-accent: mat.define-palette($jr-cyan, 500, 100, 900);
$jr-warn: mat.define-palette($jr-red, 500, 100, 900);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$jr-theme: mat.define-light-theme(
  (
    color: (
      primary: $jr-primary,
      accent: $jr-accent,
      warn: $jr-warn,
    ),
  )
);

// override Material's inferred background colors map
$backgrounds-map: map-get($jr-theme, background);
$backgrounds-map: map_merge(
  $backgrounds-map,
  (
    // we use white background on the body and set content gradient background section separately
    background: white,
    // these are defaults, which we could also override
    // status-bar: map_get($mat-grey, 300),
    // app-bar:    map_get($mat-grey, 100),
    // hover:      rgba(black, 0.04),
    // card:       white,
    // dialog:     white,
    // disabled-button: rgba(black, 0.12),
    // raised-button: white,
    // focused-button: $dark-focused,
    // selected-button: map_get($mat-grey, 300),
    // selected-disabled-button: map_get($mat-grey, 400),
    // disabled-button-toggle: map_get($mat-grey, 200),
    // unselected-chip: map_get($mat-grey, 300),
    // disabled-list-option: map_get($mat-grey, 200),
  )
);

// override Material's inferred foreground colors map
$foregrounds-map: map-get($jr-theme, foreground);
$foregrounds-map: map_merge(
  $foregrounds-map,
  (
    // these are defaults, which we could also override
    // base:              black,
    // divider:           $dark-dividers,
    // dividers:          $dark-dividers,
    // disabled:          $dark-disabled-text,
    // disabled-button:   rgba(black, 0.26),
    // disabled-text:     $dark-disabled-text,
    // elevation:         black,
    // hint-text:         $dark-disabled-text,
    // secondary-text:    $dark-secondary-text,
    // icon:              rgba(black, 0.54),
    // icons:             rgba(black, 0.54),
    // text:              rgba(black, 0.87),
    // slider-min:        rgba(black, 0.87),
    // slider-off:        rgba(black, 0.26),
    // slider-off-active: rgba(black, 0.38),
  )
);

$jr-theme: map_merge(
  $jr-theme,
  (
    background: $backgrounds-map,
    foreground: $foregrounds-map,
  )
);

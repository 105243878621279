@use '@angular/material' as mat;
@forward '@angular/material';

$material-icons-font-path: 'material-icons/iconfont/';
@use 'material-icons/iconfont/material-icons';

@use '../variables' as vars;

// import hosted webfonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap');

.material-icons {
  @extend .material-icons;
}

$font-name: 'Roboto';

/**
 * Define the base theme typography values
 *
 * mat-typography-level($font-size, $line-height: $font-size, $font-weight: 400, $font-name: null, $letter-spacing: null) {
 **/
$jr-typography-config: mat.define-typography-config(
  $font-family: $font-name,

  // HEROS
  $headline-1: null,
  $headline-2: null,
  $headline-3: null,
  $headline-4: null,

  // h1
  $headline-5: mat.define-typography-level(40px, 40px, 600, $font-name),
  // h2
  $headline-6: mat.define-typography-level(30px, 32px, 600, $font-name),
  // h3
  $subtitle-1: mat.define-typography-level(24px, 24px, 500, $font-name),
  // h4
  $subtitle-2: mat.define-typography-level(20px, 20px, 500, $font-name),
  // Base body text
  $body-1: mat.define-typography-level(16px, 24px),
  // Bolder body text
  $body-2: mat.define-typography-level(16px, 26px, 600),
  // Smaller body and hint text.
  $caption: mat.define-typography-level(14px, 18px),
  // Buttons and anchors
  $button: mat.define-typography-level(16px, 24px, 600, $font-name),
);

// override default typography config with JR-specific styles
#jr .mat-typography {
  font-weight: 400;
  line-height: 1;

  .mat-h1,
  .mat-headline,
  h1,
  .mat-h2,
  .mat-title,
  h2,
  .mat-h3,
  .mat-subtitle-1,
  h3,
  .mat-h4,
  .mat-subtitle-2,
  h4,
  .mat-h5,
  h5,
  .mat-h6,
  h6 {
    color: vars.$dark-text;
    border: none;
    margin: 0;
  }

  .mat-h1,
  .mat-headline,
  h1 {
    padding: 2px 0;
    letter-spacing: -2%;
  }

  .mat-h2,
  .mat-title,
  h2 {
    padding: 2px 0;
    letter-spacing: -2%;
  }

  .mat-h3,
  .mat-subheading-1,
  h3 {
    padding-top: 40px;
    letter-spacing: 0;
  }

  .mat-h4,
  .mat-subheading-2,
  h4 {
    padding: 3px 0 9px;
    letter-spacing: 0;
  }

  .mat-h3,
  .mat-subtitle-1,
  h3,
  .mat-h4,
  .mat-subtitle-2,
  h4 {
    color: vars.$color-primary;
  }

  .subtitle-2,
  .mat-h4,
  h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .mat-h5,
  h5,
  .mat-h6,
  h6 {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
    padding: 3px 0 1px;
    text-transform: uppercase;
  }

  small {
    font: 14px/1 $font-name;
  }

  p {
    font: 16px/24px $font-name;
    margin: 3px 0;
  }

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: vars.$color-primary;
    cursor: pointer;

    &:not(.mat-button-base) {
      line-height: 1;
    }

    &.dark {
      color: vars.$dark-text;
    }

    &.light {
      color: vars.$light-text;
    }
  }

  strong,
  b {
    font-family: $font-name;
    font-weight: 600;
  }

  summary {
    cursor: pointer;
  }
}

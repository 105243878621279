@use './variables' as vars;
@import './mixins';

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.flex {
  display: flex;
}

.light-h3 {
  color: vars.$light-text !important;
}

.no-underline {
  text-decoration: none;
}

.letter-spacing {
  letter-spacing: 0.03em;
}

.mat-icon-top {
  top: 6px;
  position: relative;
}

#desktop-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: vars.$gradient-triple;
  z-index: -1;

  #desktop-backdrop-img {
    @include mobile() {
      width: 100%;
      height: 100%;
      background: url('/assets/images/jr-circle.svg') center 100px no-repeat;
      background-size: 120%;
      opacity: 0.25;
    }
  }
}

.job-list-search-bar {
  .mat-mdc-text-field-wrapper {
    background-color: #fff !important;
    border: 1px solid #1b1b1c;
    border-radius: 100px;
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding: 0;
      }
    }
  }
  .mdc-text-field {
    will-change: unset;
    position: initial;
  }
  .mat-mdc-form-field-infix {
    display: flex;
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
  .mdc-line-ripple {
    display: none;
  }
}
.job-list-search-bar.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.capitalize {
  text-transform: capitalize;
}
.num-field ::-webkit-outer-spin-button,
.num-field ::-webkit-inner-spin-button {
  display: none;
}
.num-field {
  -moz-appearance: textfield;
}
.action-btns-job {
  .btn {
    border-radius: 40px;
    letter-spacing: 0.03em;
    padding: 12px 16px;
    height: 46px;
  }
  .cancel-btn {
    color: #5b35f4 !important;
    border-color: #5b35f4 !important;
    border-width: 2px;
    padding: 12px 24px;
  }
  .exit-btn {
    color: vars.$jimmypurple;
    letter-spacing: 0.03em;
    font-weight: 600;
    line-height: 24px;
  }
  .cancel-btn:hover {
    background-color: transparent !important;
  }
}

/* Fix for dialog notification position alignment */
.cdk-global-scrollblock {
  position: static !important;
  overflow-y: hidden;
}
.dialog-a-color {
  color: vars.$jimmypurple !important;
  font-size: 16px;
  text-decoration: none;
  font-weight: 700;
  line-height: 24px !important;
  letter-spacing: 0.03em;
}
.dialog-btn-color {
  color: #fff !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background-color: vars.$jimmypurple !important;
  border-color: vars.$jimmypurple !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: white;
}

.integration-container .mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 20px;
  margin: 0 auto;
  max-height: 808px;
}
.merge-users-container {
  width: 100% !important;
  max-width: 800px;
  margin: 0 auto !important;
  margin-top: 8vh !important;
  justify-content: center;
  max-height: calc(100vh - 14vh);
  z-index: 10;
  .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 20px;
    .mdc-dialog__container {
      margin: 0 auto;
    }
  }
}
.integration-container {
  z-index: 100;
}

// Display or hide html elements

.hide-scrollbar {
  /* Hide scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
}

@media (min-width: 1024) {
  .show-on-medium-screen {
    display: none;
  }
}

@include mobile() {
  .hide-on-medium-screen {
    display: none;
  }
}

@media (min-width: 896px) {
  .show-on-small-screen {
    display: none;
  }
}

@media (max-width: 895px) {
  .hide-on-small-screen {
    display: none;
  }
}

@include desktop() {
  .hide-on-lrg-screen {
    display: none;
  }
}

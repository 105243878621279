@use './variables' as vars;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

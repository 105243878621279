@use '../_variables.scss' as vars;
@import '../../scss/mixins.scss';

#jr .mat-typography.mat-app-background {
  background: white; // TODO: Should be set by theme, but not currently working

  &.mat-custom {
    .mat-checkbox-layout {
      white-space: normal !important;
    }

    .mat-checkbox-inner-container {
      margin: 10px 10px auto auto !important;
    }

    .mat-hint {
      margin-top: 6px;
    }

    .currency-input-icon {
      color: vars.$medium-text;
      top: 2px;
      position: relative;
    }

    mat-icon {
      &.small {
        $size: vars.$spacing-2;
        font-size: $size;
        width: $size;
        height: $size;
      }

      &.large {
        $size: vars.$spacing-4;
        font-size: $size;
        width: $size;
        height: $size;
      }

      &.xlarge {
        $size: vars.$spacing-7;
        font-size: $size;
        width: $size;
        height: $size;
      }

      &.xxlarge {
        $size: vars.$spacing-10;
        font-size: $size;
        width: $size;
        height: $size;
      }
    }

    mat-hint {
      font-size: 12px;
    }

    // TODO: remove after Angular Material bug is resolved -- https://github.com/angular/components/issues/26056
    .mat-mdc-raised-button.mat-accent {
      --mdc-protected-button-label-text-color: white !important;
    }

    .mat-mdc-raised-button,
    .mat-mdc-outlined-button .mdc-button__label {
      z-index: 0 !important;
    }

    // Snack bar overridden styles
    .snackbar-cls {
      --mdc-snackbar-container-color: #ffffff;
      --mdc-snackbar-supporting-text-color: #1f232b;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
        0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
      border-radius: 8px 8px 2px 2px;
      .mdc-snackbar__surface {
        padding-right: 0;
        .mdc-snackbar__label {
          padding: 0;
        }
      }
    }
  }
}

.mdc-button {
  min-width: 128px !important;
}

// Accordion styles overridden for FAQs Page
.mat-accordion {
  .faqs-panel {
    .mat-expansion-panel-header {
      .mat-expansion-indicator::after {
        color: vars.$jimmypurple;
      }
    }
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 16px 24px 0px;
      }
    }
  }
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: rgba(91, 53, 244, 1) !important;
  border-radius: 32px;
  box-shadow: none !important;
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: rgba(91, 53, 244, 1) !important;
  border-radius: 32px;
  box-shadow: none !important;
}

.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc1e19 !important;
  border-radius: 32px;
  box-shadow: none !important;
}

.mat-mdc-outlined-button.mat-primary {
  border-color: #5b35f4 !important;
  color: #5b35f4 !important;
  border-radius: 32px;
  box-shadow: none !important;
}

.mat-mdc-outlined-button.mat-accent {
  border-color: #5b35f4 !important;
  color: #5b35f4 !important;
  border-radius: 32px;
  box-shadow: none !important;
}

.mat-mdc-outlined-button.mat-warn {
  border-color: #dc1e19 !important;
  color: #dc1e19 !important;
  border-radius: 32px;
  box-shadow: none !important;
}

.mat-mdc-button.mat-success,
.mat-mdc-raised-button.mat-success,
.mat-mdc-outlined-button.mat-success,
.mat-mdc-stroked-button.mat-success {
  color: white !important;
  border-radius: 32px;
  box-shadow: none !important;
  --mdc-protected-button-container-color: rgba(29, 114, 49, 1) !important;
}
.mat-mdc-button.mat-success:hover,
.mat-mdc-raised-button.mat-success:hover,
.mat-mdc-outlined-button.mat-success:hover,
.mat-mdc-stroked-button.mat-success:hover {
  background-color: rgba(29, 114, 49, 0.9) !important;
}

.mat-mdc-raised-button.mat-primary:hover,
.mat-mdc-stroked-button.mat-primary:hover {
  background-color: rgba(91, 53, 244, 0.9) !important;
}

.mat-mdc-form-field-infix {
  position: inherit !important;
}

// Slide toggle
// TODO: vars. not working here
.mat-mdc-slide-toggle {
  --mdc-switch-handle-surface-color: #5b35f4;
  --mdc-switch-unselected-focus-handle-color: #808080;
  --mdc-switch-unselected-focus-state-layer-color: #808080;
  --mdc-switch-unselected-focus-track-color: #bfbfbf;
  --mdc-switch-unselected-handle-color: #808080;
  --mdc-switch-unselected-hover-handle-color: #808080;
  --mdc-switch-unselected-hover-state-layer-color: #808080;
  --mdc-switch-unselected-hover-track-color: #bfbfbf;
  --mdc-switch-unselected-pressed-handle-color: #808080;
  --mdc-switch-unselected-pressed-state-layer-color: #808080;
  --mdc-switch-unselected-pressed-track-color: #bfbfbf;
  --mdc-switch-unselected-track-color: #bfbfbf;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-handle-color: #5b35f4;
  --mdc-switch-selected-hover-handle-color: #5b35f4;
  --mdc-switch-selected-pressed-handle-color: #5b35f4;
}

.mdc-switch__track {
  background-color: #9990ff;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: rgba(91, 53, 244, 0.5);
  --mdc-linear-progress-track-color: rgba(29, 44, 55, 0.1);
  border-radius: 20px;
}

.mdc-linear-progress__bar-inner {
  --mdc-linear-progress-active-indicator-color: rgba(91, 53, 244, 0.5);
}

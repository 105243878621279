/**
  * Angular Components Material Design style definition
  *
  * WARNING: Only include this file IN A SINGLE PLACE. The @include directives present here will generate a LOT of css if included multiple times!
  **/

@use 'typography' as jr-typography;
@use 'theme' as jr-theme;

// Generate the default typography and theme styles for all Angular Material components
@include jr-theme.core();
@include jr-theme.all-component-themes(jr-theme.$jr-theme);
@include jr-typography.all-component-typographies(
  jr-typography.$jr-typography-config
);

@use './variables' as vars;

@mixin small() {
  @media (max-width: 690px) {
    @content;
  }
}

@mixin mobile() {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xlarge() {
  @media (min-width: vars.$layout-max-width) {
    @content;
  }
}

@mixin max-width-content() {
  box-sizing: border-box; // do not include the layout content padding in 100% width calculation
  width: 100%;
  max-width: vars.$layout-max-width;
  margin: 0 auto;
}

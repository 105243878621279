@use './variables' as vars;

// provide foreground and background classes for every theme color
#jr .mat-typography.mat-app-background.mat-custom {
  .background-primary {
    background-color: vars.$color-primary;
    color: vars.$light-text;
  }

  .background-accent {
    background-color: vars.$color-accent;
    color: vars.$light-text;
  }

  .background-warn {
    background-color: vars.$color-warn;
    color: vars.$light-text;
  }

  .background-jimmy {
    background-color: vars.$jimmypurple;
    color: vars.$light-text;
  }

  .background-darkSpring {
    background-color: vars.$darkSpring;
  }

  .background-darkSpringLight {
    background-color: vars.$darkSpringLight;
  }

  .foreground-jimmy {
    color: vars.$jimmypurple;
  }

  .foreground-primary {
    color: vars.$color-primary;
  }

  .foreground-accent {
    color: vars.$color-accent;
  }

  .foreground-warn {
    color: vars.$color-warn;
  }

  .foreground-salmon {
    color: vars.$salmon;
  }

  .foreground-tangerine {
    color: vars.$tangerine;
  }

  .foreground-dark-blue {
    color: vars.$cornflowerBlue;
  }

  .foreground-light-blue {
    color: vars.$carolinaBlue;
  }

  .foreground-gray-txt {
    color: vars.$gray-text;
  }

  .foreground-gray-1 {
    color: vars.$grayMeImpressed1;
  }

  .foreground-gray-2 {
    color: vars.$grayMeImpressed2;
  }

  .foreground-gray-dark {
    color: vars.$grayMeImpressed4;
  }

  .foreground-dark-spring {
    color: vars.$darkSpring;
  }

  /*Updated color globally for foreground-black. Once black-test color is updated globally revert back to commented code*/
  .foreground-black {
    // color: vars.$black-text;
    color: vars.$almostBlack;
  }

  .foreground-light {
    color: vars.$light-text;
  }
  .foreground-disabled {
    color: vars.$disabled-text;
  }

  .foreground-gradient-triple {
    background: vars.$gradient-triple-accent;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

    background-clip: text;
    color: transparent;
  }

  .background-gradient-triple-accent {
    background: vars.$gradient-triple-accent;
  }

  .background-gradient-triple {
    background: vars.$gradient-triple-accent;
  }

  .background-disabled {
    background-color: vars.$color-disabled-background;
  }

  .foreground-disabled {
    color: vars.$color-disabled-foreground;
  }

  .background-light {
    background-color: vars.$color-background-light;
  }

  .background-dark {
    background-color: vars.$color-background-dark;
  }
}

@use '../../variables' as vars;
@import '../../mixins';

.sec-top {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 0 vars.$spacing-2 vars.$spacing-2;
}

.db-h3 {
  color: vars.$light-text !important;
  padding: vars.$spacing-3 vars.$spacing-2 !important;
}

.title-wrapper {
  display: flex;
  margin-bottom: vars.$spacing-1;
}

.sec-bottom {
  border-radius: 4px 4px 0 0;
}

/** ######### MERGED DASHBOARD STYLING ######### */

.db-custom-header {
  color: vars.$grayMeImpressed2;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  max-height: 80px;
  max-width: 200px;
  white-space: nowrap;

  @include mobile() {
    color: vars.$almostBlack;
    font-size: 24px;
    line-height: 32px;
  }

  &.position-default {
    padding: vars.$spacing-2 vars.$spacing-2 vars.$spacing-2 0;
    @include desktop() {
      &.bonuses {
        @include desktop() {
          margin-left: 0;
        }
        @include mobile() {
          margin-top: -(vars.$spacing-1);
          @media (max-width: 895px) {
            margin-top: -(vars.$spacing-2);
          }
        }
      }
    }

    @include mobile() {
      &.quick-links {
        padding: vars.$spacing-2 0;
        margin-left: vars.$spacing-3;
      }
    }
  }
}

.db-welcome-msg {
  color: vars.$grayMeImpressed3;
  padding: vars.$spacing-1 0 0 0;
  text-align: left;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0.02rem;

  @include mobile() {
    padding: vars.$spacing-4 0 vars.$spacing-2 vars.$spacing-1;

    .welcome-msg-user-txt {
      font-size: 18px;
      color: vars.$almostBlack;
    }
  }
}

@use './variables' as vars;
@import './mixins';

#jr .mat-typography.mat-app-background.mat-custom {
  .grid-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: vars.$spacing-1;
    text-align: center;

    @for $i from 0 through 20 {
      // get the spacing value for this index
      $spacing: map-get(vars.$spacing-map, $i);

      &.gap-#{$i} {
        gap: $spacing;
      }
    }

    .grid-item {
    }
  }
  //new styling for input fields
  .form-input {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    text-align: left;
  }

  @for $i from 0 through 20 {
    // get the spacing value for this index
    $spacing: map-get(vars.$spacing-map, $i);

    .border-radius-#{$i} {
      border-radius: $spacing;
    }
  }

  @media (min-width: 1024px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }

    #global-layout > main:not(.securesignuppilot) {
      background: #f5f5f5;

      div.main-content-wrapper {
        background-color: white;
        box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  #global-layout > main.secureprofile,
  #global-layout > main.jobpage {
    @include mobile() {
      div.main-content-wrapper {
        padding-top: vars.$spacing-2;
      }
    }
  }

  .flex-container {
    display: flex;
    gap: vars.$spacing-1;

    @for $i from 0 through 20 {
      // get the spacing value for this index
      $spacing: map-get(vars.$spacing-map, $i);

      &.gap-#{$i} {
        gap: $spacing;
      }
    }

    &.flex-h {
      flex-direction: row;
    }

    &.flex-v {
      flex-direction: column;
    }
  }

  // create spacer styles for spacing vars 1-20
  @for $i from 0 through 20 {
    // get the spacing value for this index
    $spacing: map-get(vars.$spacing-map, $i);

    // we want to create styles for both margin and padding, so we create a map here to be able to iterate over both
    $space-types: (
      m: margin,
      p: padding,
    );
    $space-type-keys: map-keys($space-types);

    // Create styles for both the margin and padding space types
    // Examples:
    // .m-1, .my-1, .mx-1, .mt-1, .mb-1, .ml-1, .mr-1
    // .p-1, .py-1, .px-1, .pt-1, .pb-1, .pl-1, .pr-1
    @each $space-type-key in $space-type-keys {
      $space-type: map-get($space-types, $space-type-key);

      .#{$space-type-key}-#{$i} {
        #{$space-type}: $spacing;
      }
      .#{$space-type-key}y-#{$i} {
        #{$space-type}-top: $spacing;
        #{$space-type}-bottom: $spacing;
      }
      .#{$space-type-key}x-#{$i} {
        #{$space-type}-left: $spacing;
        #{$space-type}-right: $spacing;
      }
      .#{$space-type-key}t-#{$i} {
        #{$space-type}-top: $spacing;
      }
      .#{$space-type-key}b-#{$i} {
        #{$space-type}-bottom: $spacing;
      }
      .#{$space-type-key}l-#{$i} {
        #{$space-type}-left: $spacing;
      }
      .#{$space-type-key}r-#{$i} {
        #{$space-type}-right: $spacing;
      }
    }
  }
}

.onecolumn {
  grid-template-columns: none !important;
}

.signupbox {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 306px;
}

#global-layout > jrui-login-landing-page > jrui-auth-google-button,
#global-layout > jrui-login-landing-page > jrui-auth-facebook-button,
#global-layout > jrui-login-landing-page > jrui-auth-linkedin-button,
#global-layout > jrui-login-landing-page > jrui-auth-github-button {
  display: inline-block;
}
